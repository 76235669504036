import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import {useState, useEffect, useContext} from "react"
import authContext from "../../context/authContext";
import { AiFillDelete } from "react-icons/ai"
import imageCompression from 'browser-image-compression';

const OnlineOrderingCard = (props) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [availablity, setisAvailable] = useState(false)
  const [_id, set_id] = useState("");
  const [_rev, set_rev] = useState("");
  const { worker ,} = useContext(authContext);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("")
  const [value, onChange] = useState(new Date());
  useEffect(()=>{
    setName(props.meals.name)
    setPrice(props.meals.price)
    setQuantity(props.meals.quantity)
    
  }, [])

  const deleteItem = (id)=>{
    
  }

  const   handleImageUpload = async (e)=>{
    async function handleImageUpload(event) {

       const imageFile = event.target.files[0];
       console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
       console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
     
       const options = {
         maxSizeMB: 1,
         maxWidthOrHeight: 200,
         useWebWorker: true
       }
       try {
         const compressedFile = await imageCompression(imageFile, options);
         console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
         console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
         return imageCompression.getDataUrlFromFile(compressedFile)
       //   await uploadToServer(compressedFile); // write your own logic
       } catch (error) {
         console.log(error);
       }
     
     }
     let image = await handleImageUpload(e)
     setImage(image)

  }
  const changeAvailablity = (availablity, id) =>{
    let newMeal = {}
    if(availablity){
         newMeal = {
            "_id": id,
            "_rev":_rev,
            "isAvailable": "Bazinga"
    
            
        }  
    
            }else {
            newMeal = {
                "name": name,
                "description": description,
                "price": parseFloat(price),
                "quantity": parseFloat(quantity),
                "_id": id,
                "_rev":_rev,
                "image": image
        }
    }
   
    var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${worker.accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(newMeal),
        redirect: 'follow'
      };

    fetch(`https://${process.env.REACT_APP_URL}/changeAvaialblity` ,requestOptions)
    // .then(response => response.json())

    //   fetch("http://192.168.2.84:4000/newmeal" ,{
      
    //     method:"POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Origin": "*",
    //       },
    //       body: JSON.stringify(newMeal)
    // })
    .then(response => response.json())
    .then(data => {
        props.getItem()
      
      
     
          })
          .catch(error => console.log('error', error)); 

}


  return (
      <Box style={{border:"1px solid gray", padding:"30px" ,margin:"10px"}}>
    
        <FormGroup>
          <div style={{display:"flex", justifyContent:"space-between"}}>
            <FormControlLabel control={
              
              <Switch checked={props.meals.isAvailable} onClick={()=>{changeAvailablity(true, props.meals._id)}}/>} 
                label={props.meals.isAvailable} 
            />
                <AiFillDelete size={20}/>
          </div>
         
            <TextField id="outlined-basic" label="name"  onChange={(e)=>{setName(e.target.value)}} value={name} variant="outlined" /> <br />
            <TextField id="outlined-basic" label="price"  onChange={(e)=>{setPrice( e.target.value)}} value={price}variant="outlined" /> <br />
            <TextField id="outlined-basic" label="quantity" onChange={(e)=>{setQuantity(e.target.value)}}value={quantity}variant="outlined" /> <br />
            <input type="file" accept="image/*" onChange={(event)=>handleImageUpload(event)}/>
           <img src={image ? image : props.meals.image} />
            <Button onClick={()=> changeAvailablity(false, props.meals._id)}>Submit Changes</Button>
        </FormGroup>
    </Box>
  )
}

export default OnlineOrderingCard
