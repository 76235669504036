import {Box, styled, TextField,Paper, Grid, Button, Stack, InputAdornment, IconButton, CircularProgress   } from '@mui/material';
import Logo from '../assets/images/nosk1.png';
import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useContext} from 'react';
import authContext from '../context/authContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Login from "../components/Login"


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const LoginPage = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const {setIsLoggedIn,isloggedIn, worker, setWorker } = useContext(authContext)
    const [errorUsername, setErrorUsername] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleMouseUpShowPassword = () => setShowPassword(false);
    const handleMouseDownPassword = () => setShowPassword(true);
    useEffect(()=>{
      console.log("Login in false")
      if(isloggedIn){
        console.log("True logged in")
        navigate("/dashboard")
      }
    })
    
    const onLogin = (e) =>{
         e.preventDefault();
         setErrorPassword("")
         setErrorUsername("")
         if(username && password){
            setLoading(true)
            let user = {
                username: username,
                password: password
            }      
                 
            fetch('http://localhost:4000/login',{
            method: 'POST',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
                  body: JSON.stringify(user)
            })
            // .then(data=> {
                
            //     if(!data.status) { 
            //         setErrorPassword("Username/Password dont match.")  
            //         throw new Error("Username/Password dont match.")
            //     }
              
             
            
            // }) 
            .then(response=> response.json())
            .then(data=> {
              if(data.numberOfLogins === 0){
                setWorker(data);
                setIsLoggedIn(true);
                
                console.log(worker)
                navigate("/newLogin");
            } else {
              setWorker(data);
              setIsLoggedIn(true);
              if(data.role === "admin"){
                  // navigate("/dashboard")
                  // window.location.reload();
              } 
                
                setLoading(false)
            }
            })
            
            .catch((e)=>{
                // console.log(e)
                setErrorPassword("Username and password do not match")
                setLoading(false)
            })
        
        } else {
            let temp = ""
                if(!username){                
                    temp ="username is empty!"  
                    setErrorUsername(temp)           
                }
                if(!password){
                    temp = "password is empty!"
                    setErrorPassword(temp)
                }
               

        }
    
        // if(username && password){
        //     fetch("http://localhost:4000/login" ,{
        //         method:"POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //             "Access-Control-Origin": "*",
        //           },
        //           body: JSON.stringify(user)
        //     })
        //     .then(response => response.json())
        //     .then(data => {
        //         if(data.numberOfLogins === 0){
        //             setWorker(data);
        //             setIsLoggedIn(true);
        //             navigate("/newLogin");
        //         }
        //         else if(data.errorCode === "15") {
        //             setErrorPassword("Username and password do not match")                                     
        //         }
        //         else {
        //             setWorker(data);
        //             setIsLoggedIn(true);
        //             if(data.role === "admin"){
        //                 navigate("/admin")
        //             } else {
        //             // navigate("/dashboard")
        //             }
        //         }           
        //     }).catch((e)=>console.log(e))
        // }
        // else {
        //     setErrorPassword("Username and password do not match")   
        // }
        
            
        // }       
    }

  return (   
       <div> <Login /></div>
        
    
  )
}

export default LoginPage