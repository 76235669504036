import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useState, useEffect, useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import authContext from "../context/authContext";
import billContext from "../context/BillContext";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  height:"80%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {props.setEditModal(false); setOpen(false);}
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [displayNewDescription, setDisplayNewDescription] = useState(false); 
  const [newCategory, setNewCategory] = useState("")
  const [tempCategories, setTempCategories] = props.category;
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0);
  const { worker ,} = useContext(authContext);
  const {setMeals, meals} = useContext(billContext);


  //error
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [newCategoryError, setNewCategoryError] = useState(false);


  useEffect(()=>{
    // const unique = [...new Set(props.meals.map(item => item.description))]; 
    // console.log(unique)
    props.editMeal()
    console.log(props.mealToBeEdited)
    setOpen(true)
    setName(props.mealToBeEdited.name)
    setDescription(props.mealToBeEdited.description)
    setPrice(props.mealToBeEdited.price)
    setQuantity(props.mealToBeEdited.quantity)
    // const [name, setName] = useState("");
    // const [description, setDescription] = useState("");
    // const [displayNewDescription, setDisplayNewDescription] = useState(false); 
    // const [newCategory, setNewCategory] = useState("")
    // const [tempCategories, setTempCategories] = props.category;
    // const [price, setPrice] = useState("");
    // const [quantity, setQuantity] = useState(0);
   
    
 
  

  }, [props.mealToBeEdited])

  const editItem = () =>{
    setNameError(false);
    setDescriptionError(false);
    setPriceError(false);
    setQuantityError(false)
    setNewCategoryError(false)
    let newMeal = {
        "name": name,
        "description": newCategory ?  newCategory : description,
        "price": parseFloat(price),
        "quantity": parseFloat(quantity),
        "_id": props.mealToBeEdited._id,
        "_rev":props.mealToBeEdited._rev
        
    }
    let check = 1
    if(check){
      if(!newMeal.name){
        setNameError(true)
        check = 0
      }
     
      if(!description){
        setDescriptionError(true);
        check = 0;
      }
      console.log(description + "L" + newCategory)
      if(description === "newcategory"){
        if(!newCategory){
          
          setNewCategoryError(true)
          check =0
        }
      }
  
      if(!price){
        setPriceError(true)
        check = 0;
      }
  
      if(isNaN(quantity) !== false){
        console.log(isNaN(quantity))
        setQuantityError(true)
        check = 0;
      }
    }
   

    if(check) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${worker.accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(newMeal),
        redirect: 'follow'
      };

      fetch(`https://${process.env.REACT_APP_URL}/updateOne` ,requestOptions)
    // .then(response => response.json())

    //   fetch("http://192.168.2.84:4000/newmeal" ,{
      
    //     method:"POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Origin": "*",
    //       },
    //       body: JSON.stringify(newMeal)
    // })
    .then(response => response.json())
    .then(data => {
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        
      };
      
      fetch(`https://${process.env.REACT_APP_URL}/meals`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setMeals(data.data);
          // setTempMeals(data.data)
            let tempCategories = [
              ...new Set(data.data.map((el) => el.description)),
            ];
            console.log(tempCategories)
            // setCategory(tempCategories);
            // setCurrentCategory(tempCategories[0])
          //   meals.sort(function(a, b) {
          //     var textA = a.name.toUpperCase();
          //     var textB = b.name.toUpperCase();
          //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          // })
        })
        .catch(error => console.log('error', error));
     
          })
          .catch(error => console.log('error', error)); 
        handleClose()
  
    }
  }

  const deleteItem = ()=>{
    var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${worker.accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`https://${process.env.REACT_APP_URL}/removeOneMeal/${props.mealToBeEdited._id}/${props.mealToBeEdited._rev}` ,requestOptions)
    // .then(response => response.json())

    //   fetch("http://192.168.2.84:4000/newmeal" ,{
      
    //     method:"POST",
    //     headers: {removeOneMeal
    //         "Content-Type": "application/json",
    //         "Access-Control-Origin": "*",
    //       },
    //       body: JSON.stringify(newMeal)
    // })
    .then(response => response.json())
    .then(data => { 
      var requestOptions2 = {
        method: 'GET',
        headers: myHeaders,
        
      };
      fetch(`https://${process.env.REACT_APP_URL}/meals`, requestOptions2)
        .then((response) => response.json())
        .then((data) => {
          setMeals(data.data);
          // setTempMeals(data.data)
            let tempCategories = [
              ...new Set(data.data.map((el) => el.description)),
            ];
            console.log(tempCategories)
            handleClose()
            // setCategory(tempCategories);
            // setCurrentCategory(tempCategories[0])
          //   meals.sort(function(a, b) {
          //     var textA = a.name.toUpperCase();
          //     var textB = b.name.toUpperCase();
          //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          // })
        })
        .catch(error => console.log('error', error));


    }).catch((e) =>console.log(e) )
  }

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>Edit Item</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Stack  spacing={2}>
            <Typography variant="h4">Edit Item</Typography>
            
            <TextField id="outlined-basic" label="Title" variant="outlined" 
              value={name}         
              onChange={(e)=>{setName(e.target.value)}}
              error={nameError}
              helperText={nameError ? "Please Enter Title." : ""}
            />


            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">Description</InputLabel>
                  <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={description}
                      onChange={(e) => {setDescription(e.target.value)}}
                      error={descriptionError}
                     
                      >
                      {props.category.map((el)=>(
                          <MenuItem onClick={(e) => setDisplayNewDescription(false)} value={el} >{el}</MenuItem>
                          ))}
                      <MenuItem value="newcategory" onClick={()=> setDisplayNewDescription(true)} >
                          <em>Add New Category</em>
                          
                      </MenuItem>
                
                      </Select>
                </FormControl>
                  <span style={{display: description !== "newcategory" ? descriptionError ? "" : "none": "none" , color:"#8b0000"}}>Please select description</span>
            <TextField id="Description"
                style={{display: displayNewDescription ? "" : "none"}}  
                label="Add New Description" variant="outlined" 
                value={newCategory}
                onChange={(e) =>{setNewCategory(e.target.value)}}
                error={newCategoryError}
                helperText={nameError ? "Please Enter Description." : ""}
             />

            <TextField id="price" label="price" variant="outlined" 
            value={price}
            onChange={(e)=>{setPrice(e.target.value)}} 
            error={priceError}
            helperText={priceError ? "Please Enter Price." : ""}
            />
            
            <TextField id="quantity" label="quantity" variant="outlined" 
              value={quantity}
              onChange={(e)=>{setQuantity(e.target.value)}}
              error={quantityError}
              helperText={quantityError ? "Please Enter Quantity." : ""}
            />
            <Button onClick={() => editItem()}>Edit Item</Button>
            <Button onClick={() => deleteItem()}>Delete Item</Button>
            </Stack>

          
        </Box>
      </Modal>
    </div>
  );
}
