import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {useState, useContext} from "react";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Pos from "./Pos";
import PosTemp from './PosTemp'
import POSComplete from './POSComplete'
import { useNavigate } from 'react-router-dom';
import authContext from '../context/authContext';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export default function TemporaryDrawer() {
  const navigate = useNavigate()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [index , setIndex] = useState(0)
  const { worker } = useContext(authContext);

  const logout = () =>{
    let token = worker.accessToken
    fetch(`https://${process.env.REACT_APP_URL}/logout`, {
      method: "POST",
    
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(worker),
    }).then(response => response.json()).then((data)=>window.location.href = '/').catch((e)=>console.log(e))

  }
  

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['POS'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
          <ListItem disablePadding>
        <ListItemButton onClick={()=>{navigate("/dashboard")}}>
            POS
        </ListItemButton>
    </ListItem>
        <ListItem disablePadding>
      <ListItemButton onClick={()=>{navigate("/bills")}}>
            View Bills
        </ListItemButton>
      </ListItem>
     
  <ListItem disablePadding>
      <ListItemButton onClick={()=>{navigate("/newLogin")}}>
            Change Password
        </ListItemButton>
  </ListItem>
  <ListItem disablePadding>
        <ListItemButton onClick={()=>{navigate("/onlineorders")}}>
            Manage Online Orders
        </ListItemButton>
  
      </ListItem>
    <ListItem disablePadding>
        <ListItemButton onClick={()=>{navigate("/expense")}}>
            Expense
        </ListItemButton>

      </ListItem>

  <ListItem disablePadding>
        <ListItemButton onClick={logout}>
            Logout
        </ListItemButton>

      </ListItem>
        {/* {['View Bills', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
    </Box>
  );

  return (
    <>
      

<Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Item>{['Menu'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </Item>
     
      </Stack>
    </Box>
  
      
    </>
  );
}
