import DashBoard from "../components/DashBoard"

const DashboardPage = () => {
  return (
    <div>
      <DashBoard />
      <br />

    </div>
  )
}

export default DashboardPage