import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useEffect, useState, useContext, useCallback } from 'react';
import Button from '@mui/material/Button';
import billContext from "../context/BillContext";
import { AiFillEdit } from "react-icons/ai";
import UpdateItemModal from "./UpdateItemModal.js";
import authContext from "../context/authContext";



const TabsGenerated = (props) => {
  const {meals, setMeals,bills,  setBills, currentCategory, total, setTotal, addItem} = useContext(billContext)
  const [sortedMeals, setSortedMeals] = useState([]);
  const [openEditModal, setEditModal] = useState(false);
  const { worker } = useContext(authContext);
  const [mealToBeEdited , setMealToBeEdited] = useState("")
  useEffect(()=>{
    console.log("Tabs generated mounted")
  },[])

  const editItem = (id) =>{
    console.log(id)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + worker.accessToken);
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      // body:JSON.stringify({id: id})
    };
    
    fetch(`https://${process.env.REACT_APP_URL}/getMeal/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data)
        setMealToBeEdited({
          description: data.data.description,
          name: data.data.name,
          price: data.data.price,
          quantity: data.data.quantity,
          _id: data.data._id,
          _rev: data.data._rev,
        })
      })
      setEditModal(true)
      .catch(error => console.log('error', error));
    
    console.log(id)
  }

  const editMeal = useCallback(()=>{ return mealToBeEdited}, [mealToBeEdited])
    
  return (
   
       
     
    meals && meals.length > 0 && meals.filter((el)=> el.description.includes(currentCategory)).map((el)=>(
      <>
   
 
            <Button variant={props.editMode ? "outlined" :"contained" }
             key={el._id} 
             id={el._id} 
             price={el.price} 
             quantity={1} 
             style={{margin:"5px", minWidth:"110px", maxWidth:"110px", height:"120px",
              wordWrap:"word-break",
            }}
             onClick={(e)=>{
            props.editMode === false ?  
              addItem(e.target.id)
               :
               editItem(e.target.id)

            
            }}
            
             > 
                    {el.name} 
                </Button> 
                { openEditModal && mealToBeEdited &&
                < UpdateItemModal 
                onChangeMeal={props.refreshMeal} 
                category={props.category} 
                setEditModal={setEditModal}
                editMeal={editMeal}
                mealToBeEdited={mealToBeEdited}
                
                />}
      
        </>
    ))
 
  )}

export default TabsGenerated
