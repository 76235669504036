import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useState, useEffect, useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import authContext from "../../context/authContext";
import imageCompression from 'browser-image-compression';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  height:"90%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [displayNewDescription, setDisplayNewDescription] = useState(false); 
  const [newCategory, setNewCategory] = useState("")
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState(0);
  const { worker } = useContext(authContext);

  //error
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [newCategoryError, setNewCategoryError] = useState(false);
  const [image, setImage] = useState("")
  const [value, onChange] = useState(new Date());


  const addItem = () =>{
    setNameError(false);
    setDescriptionError(false);
    setPriceError(false);
    setQuantityError(false)
    setNewCategoryError(false)
    let newMeal = {
        "name": name,
        "description": newCategory ?  newCategory : description,
        "price": parseFloat(price),
        "quantity": parseFloat(quantity),
        "isAvailable": false,
        "image": image
        
    }
    let check = 1
    if(check){
      if(!newMeal.name){
        setNameError(true)
        
        check = 0
      }
     
  

   
      if(price <= 0){
        setPriceError(true)
        check = 0;
      }
  
      if(isNaN(quantity) !== false){
        console.log(isNaN(quantity))
        setQuantityError(true)
        check = 0;
      }
    }
    console.log(check)

    if(check) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${worker.accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(newMeal),
        redirect: 'follow'
      };

      fetch(`https://${process.env.REACT_APP_URL}/addnewonlinemeal` ,requestOptions)
    // .then(response => response.json())

    //   fetch("http://192.168.2.84:4000/newmeal" ,{
      
    //     method:"POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Origin": "*",
    //       },
    //       body: JSON.stringify(newMeal)
    // })
    .then(response => response.json())
    .then(data => {
        console.log(data.data)
        props.getItem()
        // props.onChangeMeal([...data.data])    
        handleClose()
    })
    }
  }

  const   handleImageUpload = async (e)=>{
    async function handleImageUpload(event) {

       const imageFile = event.target.files[0];
       console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
       console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
     
       const options = {
         maxSizeMB: 1,
         maxWidthOrHeight: 200,
         useWebWorker: true
       }
       try {
         const compressedFile = await imageCompression(imageFile, options);
         console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
         console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
         return imageCompression.getDataUrlFromFile(compressedFile)
       //   await uploadToServer(compressedFile); // write your own logic
       } catch (error) {
         console.log(error);
       }
     
     }
     let image = await handleImageUpload(e)
     setImage(image)

  }

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>ADD ITEM TO MENU</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Stack  spacing={2}>
            <Typography variant="h4">Add New Item</Typography>
            
            <TextField id="outlined-basic" label="Name" variant="outlined" value={name}         
              onChange={(e)=>{setName(e.target.value)}}
              error={nameError}
              helperText={nameError ? "Please Enter Title." : ""}
            />


           

            <TextField id="price" label="price" variant="outlined" 
                value={price}
                onChange={(e)=>{setPrice(e.target.value)}} 
                error={priceError}
                helperText={priceError ? "Please Enter Price." : ""}
            />
            
            <TextField id="quantity" label="quantity" variant="outlined" 
              value={quantity}
              onChange={(e)=>{setQuantity(e.target.value)}}
              error={quantityError}
              helperText={quantityError ? "Please Enter Quantity." : ""}
            />
            <input type="file" accept="image/*" onChange={(event)=>handleImageUpload(event)}/>
            <div stlye={{height:"20px"}}>
            <img src={image} />
            </div>
            <Button onClick={()=>addItem()}>Add New online Meal</Button>
            </Stack>

          
        </Box>
      </Modal>
    </div>
  );
}
