import React from "react";
import { useEffect, useState, useContext } from "react";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Logo from "../assets/images/nosk1.png";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TabsGenerated from "./TabsGenerated";
import AddItemModal from "./AddItemModal";
import PaymentCalculator from "./PaymentCalculator";
import Footer from "./Footer"
import authContext from "../context/authContext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  border: "none",

  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Pos = () => {
  const [meals, setMeals] = useState([]);
  const [bill, setBill] = useState([]);
  const [total, setTotal] = useState(0);
  const [tempbill, setTempBill] = useState([]);
  const [category, setCategory] = useState([]);
  const [showPaymentModule, setShowPaymentModule] = useState(false)
  const { worker } = useContext(authContext);
  //add item locally
  const addItem = (meal) => {
    // console.log(meals)
    let mealObj = meals.filter((el) => el._id === meal);
    let index = bill.findIndex((el) => el.id === mealObj[0]._id);
    console.log(index);
    console.log(mealObj);
    // console.log(index)
    let length = bill.length;
    let mealObjTemp = {
      id: mealObj[0]._id,
      rev: mealObj[0]._rev,
      name: mealObj[0].name,
      quantity: 1,
      price: mealObj[0].price,
      description: mealObj[0].description,
    };
    if (index >= 0) {
      // console.log("here")
      let temp = bill;
      mealObjTemp.quantity = mealObjTemp.quantity + temp[index].quantity;
      mealObjTemp.price = mealObjTemp.price + temp[index].price;
      temp[index] = mealObjTemp;
      // console.log(temp[index])
      // setBill([...temp]);
    } else {
      console.log("It doesnt exist")
      let x = bill
      x.push(mealObjTemp)
      // setBill([...bill, mealObjTemp]);
    }
    setTotal(mealObj[0].price + total);
  };

  //submit 
  const submit = () => {
    if(bill.length){

      fetch("http://localhost:4000/cart" ,{
        method:"POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Origin": "*",
            "Authorization": "Bearer " + worker.accessToken
          },
          body: JSON.stringify(bill)
    })
    .then(response => response.json())
    .then(data => {console.log(data.data)
      setMeals([...data.data]);
      let temp = []
      setBill([...temp])
    
  
  
        onShowPaymentModule();
      
      console.log("Paymenmodue" +showPaymentModule)
  
    })
    .catch(err => console.log(err))
    }
  };

  //payment module
  const onShowPaymentModule = () =>{
    setShowPaymentModule(!showPaymentModule)
    
  }
  const resetTotal = () =>{
    setTotal(0);
  }

//remove from cart
  const minusItem = (meal) => {
    // console.log(meals)
    
    let mealObj = meals.filter((el) => el._id === meal);
    let index = bill.findIndex((el) => el.id === mealObj[0]._id);
    console.log(index);
    // console.log(index)
    let length = bill.length;
    let mealObjTemp = {
      id: mealObj[0]._id,
      rev: mealObj[0]._rev,
      name: mealObj[0].name,
      quantity: 1,
      price: mealObj[0].price,
      description: mealObj[0].description,
    };
    if (index >= 0) {
      // console.log("here")
      let temp = bill;

      mealObjTemp.quantity = temp[index].quantity - mealObjTemp.quantity;
      mealObjTemp.price = temp[index].price - mealObjTemp.price;
      // console.log(mealObjTemp);
      if (mealObjTemp.price > 0) {
        temp[index] = mealObjTemp;
        // console.log(temp[index])

        setBill([...temp]);
      } else {
        console.log("temp");
        temp.splice(index, 1);
        setBill([...temp]);
      }
    }
    if (total - mealObj[0].price >= 0) {
      setTotal(total - mealObj[0].price);
    }
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + worker.accessToken);
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
    };
    
    fetch("http://localhost:4000/meals", requestOptions)
    
      .then((response) => response.json())
      .then((data) => {
        setMeals(data.data);
        let tempCategories = [
          ...new Set(data.data.map((el) => el.description)),
        ];
        setCategory([...tempCategories]);
        meals.sort(function(a, b) {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
        // console.log(meals)
        // meals.forEach((el)=>{console.log(el._id)})
      })
      .catch((e) => console.log(e));
  }, []);


  const refreshMeal = (data) =>{
    setMeals(data.data);
    let tempCategories = [
      ...new Set(data.data.map((el) => el.description)),
    ];
    setCategory([...tempCategories]);
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh", backgroundColor: "#FFBF00" }}
      >
        <Paper
          elevation={3}
          sx={{ width: { xs: "95%", lg: "95%" } }}
          style={{
            paddingTop: "20px",
            backgroundColor: "white",
            height: "70vh",
          }}
          square
        >
          <Box sx={{ flexGrow: 1, margin: "20px" }}>
            
            <h1>Your Total: ${total}</h1> 
            <AddItemModal onChangeMeal={refreshMeal} category={category} style={{margin:"auto", width:"50%"}}/>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Item>
                  <Tabs>
                    <TabList>
                      {
                      category.map((el) => (
                        <Tab key={((Math.random() * 10) + 1)}>{el}</Tab>
                      ))}
                    </TabList>

                    {
                      
                        category.map((el) => ( 
                          
                          <TabPanel key={((Math.random() * 10) + 1)}>
                          <TabsGenerated cat={el} meals={meals} addItem={addItem} />
                        </TabPanel>
                        ))
                    
                    }
                  </Tabs>
                  <Grid></Grid>
                </Item>
              </Grid>

              <Grid item xs={4}>
                <Item>
                  <imgu
                    src={Logo}
                    alt="logo"
                    style={{ width: "30%", height: "auto" }}
                  />
                  <hr></hr>
                  <Paper style={{ maxHeight: "170px", overflow: "auto" }}>
                    <List>
                      {bill.map((el) => (
                        <div
                          style={{
                            zIndex: "1",
                            position: "abosolute",
                            right: "10",
                            paddingBottom: "10px",
                          }}
                          key={el.id}
                        >
                          <Stack direction="row" spacing={2}>
                            <div
                              style={{ marginLeft:"10px", width: "25%", marginBottom: "10px" }}
                            >
                              {el.name}
                            </div>
                            <div style={{ maxWidth: "50%", width: "50%" }}>
                              <button
                                style={{ width: "25%", marginRight: "10px", borderRadius:"20px"
                                , backgroundColor:"white",
                                border:"1px solid #2196f3 " }}
                                id={el.id}
                                onClick={(e) => addItem(e.target.id)}
                              >
                                +
                              </button>
                              {el.quantity}
                              <button
                                id={el.id}
                                onClick={(e) => minusItem(e.target.id)}
                                style={{ width: "25%", marginLeft: "10px",
                                width: "25%", borderRadius:"20px"
                                , backgroundColor:"white",
                                border:"1px solid #2196f3 " }}
                              >
                                -
                              </button>
                            </div>
                            <div style={{ maxWidth: "30%", width: "30%" }}>
                              ${el.price}
                            </div>
                          </Stack>
                        </div>
                      ))}
                    </List>
                  </Paper>
                    <Button variant="contained" onClick={submit} style={{margin:"20px"}}>
                    Complete CheckOut
                  </Button>
                </Item>
              </Grid>
              <Grid item xs={4}>
               <PaymentCalculator total={total}
                   showPaymentModule={showPaymentModule} 
                   onShowPaymentModule={onShowPaymentModule}
                   resetTotal={resetTotal}
                   />           
                <Item>              
                </Item>
              </Grid>
              <Grid item xs={8}>
                <Item>
                
                </Item>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <br />
                  <Footer />
      </Grid>
    </>
  );
};

export default Pos;
