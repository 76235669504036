import React from "react";
import { useEffect, useState, useContext, useRef } from "react";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Logo from "../assets/images/nosk1.png";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import TabsGenerated from "./TabsGenerated";
import AddItemModal from "./AddItemModal";
import PaymentCalculator from "./PaymentCalculator";
import Footer from "./Footer"
import authContext from "../context/authContext";
import Bill from "./Bill"
import Testes from "./Testes"
import billContext from "../context/BillContext";
import Test from "./Testes";
import { AiFillEdit } from "react-icons/ai";




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "yellow" : "white",
  ...theme.typography.body2,
  border: "none",

  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Pos = () => {

  const [tempMeals, setTempMeals] = useState([])
  const [bill, setBill] = useState([]);
  // const [total, setTotal] = useState(0);
  const [tempbill, setTempBill] = useState([]);
  const [category, setCategory] = useState([]);
  const [showPaymentModule, setShowPaymentModule] = useState(false)
  const { worker } = useContext(authContext);
  const {meals, setMeals, currentCategory, setCurrentCategory, total, setTotal, bills, setBills} = useContext(billContext)
   const [editMode, setEditMode] = useState(false)
  const [item, setItem] = useState("")
  useEffect(()=>{
 
    if(worker.accessToken) {
      
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + worker.accessToken);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        
      };
      
      fetch(`https://${process.env.REACT_APP_URL}/meals`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setMeals(data.data);
          setTempMeals(data.data)
            let tempCategories = [
              ...new Set(data.data.map((el) => el.description)),
            ];
            console.log(tempCategories)
            setCategory(tempCategories);
            setCurrentCategory(tempCategories[0])
          //   meals.sort(function(a, b) {
          //     var textA = a.name.toUpperCase();
          //     var textB = b.name.toUpperCase();
          //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          // })
        })
        .catch(error => console.log('error', error));
    
    }
    // fetch("http://localhost:4000/testes").then((data)=>(data.json())).catch((e)=>console.log(e))
  },[worker])

  //add item locally
  const addItem = (meal) => {
    
    console.log(meal)
    let mealObj = tempMeals.filter((el) => el._id === meal);
    console.log(meals)
    
    let index = bill.findIndex((el) => el.id === mealObj[0]._id);
    // console.log(index);
    console.log(mealObj);
    // console.log(index)
    let length = bill.length;
    let mealObjTemp = {
      id: mealObj[0]._id,
      rev: mealObj[0]._rev,
      name: mealObj[0].name,
      quantity: 1,
      price: mealObj[0].price,
      description: mealObj[0].description,
    };
    if (index >= 0) {
      // console.log("here")
      let temp = bill;
      mealObjTemp.quantity = mealObjTemp.quantity + temp[index].quantity;
      mealObjTemp.price = mealObjTemp.price + temp[index].price;
      temp[index] = mealObjTemp;
      // console.log(temp[index])
       setBill(temp);
      
    } 
    else {
      
      setBill(bills => [...bills, mealObjTemp]);
    }
    // setTotal(mealObj[0].price + total);
     
   
  };


  //submit 

  const submit = (change) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${worker.accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    let temp = bills
    //temp.push({change: change})
    temp[0].change = change
    temp[0].total = total
    
    console.log("change" + change)
    if(bills.length){
      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(temp),
        redirect: 'follow'
      };

      fetch(`https://${process.env.REACT_APP_URL}/cart` ,requestOptions)
    .then(response => response.json())
    .then(data => {console.log(data.data)
      setMeals([...data.data]);
      let temp = []
      setBills([...temp])
    
  
  
        onShowPaymentModule();
      
    
  
    })
    .catch(err => console.log(err))
    }
  };

  //payment module
  const onShowPaymentModule = () =>{
    setShowPaymentModule(!showPaymentModule)
    
  }
  const resetTotal = () =>{
    setTotal(0);
  }

//remove from cart
  const minusItem = (meal) => {
    // console.log(meals)
    
    let mealObj = meals.filter((el) => el._id === meal);
    let index = bill.findIndex((el) => el.id === mealObj[0]._id);
    console.log(index);
    // console.log(index)
    let length = bill.length;
    let mealObjTemp = {
      id: mealObj[0]._id,
      rev: mealObj[0]._rev,
      name: mealObj[0].name,
      quantity: 1,
      price: mealObj[0].price,
      description: mealObj[0].description,
    };
    if (index >= 0) {
      // console.log("here")
      let temp = bill;

      mealObjTemp.quantity = temp[index].quantity - mealObjTemp.quantity;
      mealObjTemp.price = temp[index].price - mealObjTemp.price;
      // console.log(mealObjTemp);
      if (mealObjTemp.price > 0) {
        temp[index] = mealObjTemp;
        // console.log(temp[index])

        setBill([...temp]);
      } else {
        console.log("temp");
        temp.splice(index, 1);
        setBill([...temp]);
      }
    }
    if (total - mealObj[0].price >= 0) {
      setTotal(total - mealObj[0].price);
    }
  };

  // useEffect(() => {
  //   console.log(worker.accessToken + "access token")

  //   fetch("http://localhost:4000/testes").then((data)=>console.log(data)).catch((e)=>console.log(e))

  //   fetch("http://localhost:4000/meals",{
  //     method: "GET",
  //     credentials: 'include',
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Access-Control-Origin": "*",
  //         "Authorization": `Bearer ${worker.accessToken}`
  //       },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setMeals(data.data);
  //       let tempCategories = [
  //         ...new Set(data.data.map((el) => el.description)),
  //       ];
  //       setCategory([...tempCategories]);
  //       meals.sort(function(a, b) {
  //         var textA = a.name.toUpperCase();
  //         var textB = b.name.toUpperCase();
  //         return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  //     })
  //       // console.log(meals)
  //       // meals.forEach((el)=>{console.log(el._id)})
  //     })
  //     .catch((e) => console.log(e));
  // }, []);




  const refreshMeal = (data) =>{
    setMeals(data.data);
    let tempCategories = [
      ...new Set(data.data.map((el) => el.description)),
    ];
    setCategory([...tempCategories]);
  }

  return ( 
        <div >
          <h1>Your Total: ${total}</h1> 
          <div>
          <AddItemModal onChangeMeal={refreshMeal} category={category} style={{margin:"auto", width:"50%"}}/>
        <div style={{display:"flex", alignItems:"flex-end", justifyContent:"right"}}>
                <Button onClick={(e)=>{setEditMode(!editMode)}}>< AiFillEdit size={30}/> </Button>
          </div>

          {category.map((el, i)=>(
            <Button key ={i}
            id={el} 
            onClick={(e)=>setCurrentCategory(e.target.id)}
            >
            {el}
          </Button>
          ))}
          <div style={{
            display:"flex", 
            justifyContent:"center", 
            flexDirection:"column", 
            alignItems:"stretch"
          }}>
            <div>
          <TabsGenerated editMode={editMode} onChangeMeal={refreshMeal} category={category} />

            </div>

          </div>
          <PaymentCalculator 
                  total={total}
                   showPaymentModule={showPaymentModule} 
                   completeCheckout={submit}
                   onShowPaymentModule={onShowPaymentModule}
                   resetTotal={resetTotal}
                   />  
            <Button variant="contained" 
              onClick={()=>{onShowPaymentModule()}}
              style={{margin:"20px"}}
             >
                    Complete CheckOut
                  </Button>
            <Bill />
          <div>
            
          </div>
        
          {/* {                     
                      category.map((el, i) => (                        
                          <TabsGenerated key={i} cat={el} addItem={addItem} />
               
                      ))
                  
                  } */}
          </div>
        
       
        </div>      
          );
};

export default Pos;
