import React from 'react'
import Header from "../components/Header"
import OnlineORderPortal from '../components/OnlineOrderPortalComponents/OnlineOrderPortal'
const OnlineOrders = () => {
  return (
    <div>
      <Header />
      <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
      < OnlineORderPortal/>

      </div>

    </div>
  )
}

export default OnlineOrders
