import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Logo from "../assets/images/nosk1.png";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useContext } from "react";
import authContext from "../context/authContext";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import Header from "./Header"


const ChangePassword = () => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const { worker } = useContext(authContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const onChangePassword = () => {
    if(!password1 || !password2){
      setError("Passwords Field are empty")
    }
 

    else if(password1 === worker.password){
      setError("please use new password, old password detected");
    }

    else {
    
      worker.password = password1;
      let token = worker.accessToken
      fetch(`https://${process.env.REACT_APP_URL}/changepassword`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Origin": "*",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify(worker),
      })
        .then((response) => response.json())
        .then((data) => {
          navigate("/dashboard");
  
          console.log(worker.password = "");
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
    <Header />
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh", backgroundColor: "#FFBF00" }}
    >

      <Paper
        elevation={3}
        sx={{ width: { xs: "80%", lg: "40%" } }}
        style={{
          paddingTop: "20px",
          backgroundColor: "white",
          height: "80vh",
        }}
        square
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Stack spacing={2}>
            <h1 style={{ fontSize: "30px" }}>
              Welcome,{" "} 
              {worker.username.charAt(0).toUpperCase() +
                worker.username.slice(1)}{" "}
            </h1>
            <img src={Logo} alt="logo" style={{ height: "30vh" }} />

            <TextField
              id="outlined-password-input"
              label="New Password"
              type="password"
              onChange={(e) => {
                setPassword1(e.target.value);
              }}
            />
            <TextField
              id="outlined-password"
              label="Confirm Password"
              type="password"
              autoComplete="current-password"
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
            />
            <span style={{ color: "red", fontWeight: "bold" }}>
              {error ? <ErrorOutlineIcon /> : ""} {error}
            </span>
            <Button
              variant="inherit"
              size="small"
              style={{
                color: "white",
                border: "1px solid #FFBF00",
                borderRadius: "10px",
                backgroundColor: "#FFBF00",
              }}
              onClick={onChangePassword}
            >
              Change Password
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Grid>
</>
  );
};

export default ChangePassword;
