import {Box, styled, TextField,Paper, Grid, Button, Stack, InputAdornment, IconButton, CircularProgress   } from '@mui/material';
import Logo from '../assets/images/nosk1.png';
import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useContext} from 'react';
import authContext from '../context/authContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const {setIsLoggedIn,isloggedIn, worker, setWorker } = useContext(authContext)
    const [errorUsername, setErrorUsername] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleMouseUpShowPassword = () => setShowPassword(false);
    const handleMouseDownPassword = () => setShowPassword(true);
    useEffect(()=>{
      console.log("Login in false")
  
      if(isloggedIn){
        console.log("True logged in")
        navigate("/dashboard")
      }
    })
    
    const onLogin = (e) =>{
         e.preventDefault();
         setErrorPassword("")
         setErrorUsername("")
         if(username && password){
            setLoading(true)
            let user = {
                username: username,
                password: password
            }      
                 
            fetch(`https://${process.env.REACT_APP_URL}/login`,{
            method: 'POST',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
                  body: JSON.stringify(user)
            })
            // .then(data=> {
                
            //     if(!data.status) { 
            //         setErrorPassword("Username/Password dont match.")  
            //         throw new Error("Username/Password dont match.")
            //     }
              
             
            
            // }) 
            .then(response=> response.json())
            .then(data=> {
              if(data.numberOfLogins === 0){
                setWorker(data);
                setIsLoggedIn(true);
                
                console.log(worker)
                 navigate("/newLogin");
            } else {
              setWorker(data);
              setIsLoggedIn(true);
              if(data.role === "admin"){
                  // navigate("/dashboard")
                   //window.location.reload();
              } 
                
                setLoading(false)
            }
            })
            
            .catch((e)=>{
                // console.log(e)
                setErrorPassword("Username and password do not match")
                setLoading(false)
            })
        
        } else {
            let temp = ""
                if(!username){                
                    temp ="username is empty!"  
                    setErrorUsername(temp)           
                }
                if(!password){
                    temp = "password is empty!"
                    setErrorPassword(temp)
                }
               

        }
    
        // if(username && password){
        //     fetch("http://localhost:4000/login" ,{
        //         method:"POST",
        //         headers: {
        //             "Content-Type": "application/json",
        //             "Access-Control-Origin": "*",
        //           },
        //           body: JSON.stringify(user)
        //     })
        //     .then(response => response.json())
        //     .then(data => {
        //         if(data.numberOfLogins === 0){
        //             setWorker(data);
        //             setIsLoggedIn(true);
        //             navigate("/newLogin");
        //         }
        //         else if(data.errorCode === "15") {
        //             setErrorPassword("Username and password do not match")                                     
        //         }
        //         else {
        //             setWorker(data);
        //             setIsLoggedIn(true);
        //             if(data.role === "admin"){
        //                 navigate("/admin")
        //             } else {
        //             // navigate("/dashboard")
        //             }
        //         }           
        //     }).catch((e)=>console.log(e))
        // }
        // else {
        //     setErrorPassword("Username and password do not match")   
        // }
        
            
        // }       
    }

  return (   
        <Grid container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh', backgroundColor:"#FFBF00" }}>
                 

        {!worker.isLoggedIn &&<Paper elevation={3} 
                sx={{width:{xs:"70%", lg:"50%"}}}
                style={{
                    paddingTop:"20px",
                    backgroundColor:"white",
                    height:"auto",
                    padding:"30px"
                    }} square > 
                          
                    <Box sx={{ display: 'flex', justifyContent:'center', alignItems:'center' }}>
                    <Grid container spacing={2} style={{alignItems:"center", justifyContent:"center"}}>
                      <Grid item xs={12} md={12} >
                      <Item>
                        <img src={`${Logo}?w=164&h=164&fit=crop&auto=format`} 
                        alt="logo"
                        srcSet={`${Logo}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        style={{height:"30vh", width:"auto", overflow:"hidden"}}
                        loading="lazy"
                        />   
                      </Item>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Item>
            <form onSubmit={onLogin}>
                                    <Stack spacing={2}>
                                        <TextField
                                            id="username"
                                            label="Username"
                                            type="username"
                                            onChange={(e)=>{setUsername(e.target.value)}}
                                            value={username}
                                            error={errorUsername? true : false}
                                            />  
                                        {errorUsername && 
                                           <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            <ErrorOutlineIcon />
                                            <span style={{color:"red"}}> {errorUsername}</span>
                                        </div>  
                                       } 
                                            
                                            <TextField
                                                id="outlined-password-input"
                                                label="Password"
                                                type={showPassword ? "text" : "password"}
                                                autoComplete="current-password"
                                                onChange={(e)=>{setPassword(e.target.value)}}
                                                error={errorPassword? true : false}
                                                value={password}
                                                InputProps={{ // <-- This is where the toggle button is added.
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <IconButton
                                                          aria-label="toggle password visibility"
                                                          
                                                          onClick={(e)=>handleMouseDownPassword(e)}
                                                          onMouseUp={(e)=>handleMouseUpShowPassword(e)}
                                                        >
                                                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                        </IconButton>
                                                      </InputAdornment>
                                                    )
                                                  }}
                                                />
                    
                                        {errorPassword && 
                                           <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}>
                                            <ErrorOutlineIcon />
                                            <span style={{color:"red"}}>{errorPassword}</span>
                                        </div>  
                                       } 

                                         
                                    
                                            <Button variant="inherit" size="small"                                   
                                                style={{color:"white",
                                                border:"1px solid #FFBF00",
                                                borderRadius:"10px",
                                                backgroundColor:"#FFBF00"
                                                }}
                                                type="submit"
                                                disabled={loading}
                                            
                                                // onClick={onLogin}
                                                >
                                                    {loading ? <CircularProgress /> : "Login" }
                                            
                                            </Button>
                                        </Stack>
                                </form> 
                        </Item>
                        
                    

                        
                      </Grid>
                    </Grid>
                                  
                </Box>
        </Paper>
}
        </Grid>
        
    
  )
}

export default Login
