import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import {useContext, useState, useEffect} from 'react';
import authContext from './context/authContext'
import ChangePassword from "./pages/ChangePassword";
import DashboardPage from "./pages/DashboardPage";
import PosTemp from "./components/PosTemp";
import BillPage from "./pages/BillPage";
import BillContext from "./context/BillContext";
import OnlineOrdersPage from "./pages/OnlineOrdersPage";
import ExpensePage from "./pages/ExpensePage";

function App() {
  const [isloggedIn, setIsLoggedIn] = useState(false);
  const [worker, setWorker] = useState({});
  const [meals, setMeals] = useState({});
  const [total, setTotal] = useState(0);
  const [bills, setBills] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const addItem = (meal)=>{
    console.log(meal)
    let mealObj = meals.filter((el) => el._id === meal);
     console.log(mealObj)
    
    let index = bills.findIndex((el) => el.id === mealObj[0]._id);
    // // console.log(index);
    // console.log(mealObj);
    // // console.log(index)
    // let length = bill.length;
    let mealObjTemp = {
      id: mealObj[0]._id,
      rev: mealObj[0]._rev,
      name: mealObj[0].name,
      quantity: 1,
      price: mealObj[0].price,
      description: mealObj[0].description,
    };
    if (index >= 0) {
      // console.log("here")
      let temp = bills;
      mealObjTemp.quantity = mealObjTemp.quantity + temp[index].quantity;
      mealObjTemp.price = mealObjTemp.price + temp[index].price;
      temp[index] = mealObjTemp;
      // console.log(temp[index])
       setBills(temp);
      
    } 
    else {
      
      setBills(bills => [...bills, mealObjTemp]);
    }
     setTotal(mealObj[0].price + total);
     
   
    // console.log("TESTINGGGGGGGGGGG")
    // setBills(e.target.id)
    // setTotal(total)

  }
  const minusItem = (meal) => {
    // console.log(meals)
    
    let mealObj = meals.filter((el) => el._id === meal);
    let index = bills.findIndex((el) => el.id === mealObj[0]._id);
    console.log(index);
    // console.log(index)
    // let length = bill.length;
    let mealObjTemp = {
      id: mealObj[0]._id,
      rev: mealObj[0]._rev,
      name: mealObj[0].name,
      quantity: 1,
      price: mealObj[0].price,
      description: mealObj[0].description,
    };
    if (index >= 0) {
      // console.log("here")
      let temp = bills;

      mealObjTemp.quantity = temp[index].quantity - mealObjTemp.quantity;
      mealObjTemp.price = temp[index].price - mealObjTemp.price;
      // console.log(mealObjTemp);
      if (mealObjTemp.price > 0) {
        temp[index] = mealObjTemp;
        // console.log(temp[index])

        setBills([...temp]);
      } else {
        console.log("temp");
        temp.splice(index, 1);
        setBills([...temp]);
      }
    }
    if (total - mealObj[0].price >= 0) {
      setTotal(total - mealObj[0].price);
    }
  };


  
  useEffect(()=>{
    console.log("POS temp remounted")
     setInterval(() => {
      /*
          Run a function or set any state here
      */
          fetch(`https://${process.env.REACT_APP_URL}/checkLogin`,
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }}).
          then((response)=>response.json()).
          then((data)=>{if(data.isLoggedIn){
            setIsLoggedIn(true)
            setWorker({accessToken: data.accessToken})
      
          }}).catch((e)=>console.log(e))
    }, 60000);
    fetch(`https://${process.env.REACT_APP_URL}/checkLogin`,
    {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }}).
    then((response)=>response.json()).
    then((data)=>{if(data.isLoggedIn){
      setIsLoggedIn(true)
      setWorker({accessToken: data.accessToken})

    }}).catch((e)=>console.log(e))
  
    const interval = setInterval(() => {
      console.log("getting the stuff")
      fetch("http://localhost:4000/checkLogin",
          {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }}).
          then((response)=>response.json()).
          then((data)=>{if(data.isLoggedIn){
            setIsLoggedIn(true)
            setWorker({accessToken: data.accessToken})

          }}).catch((e)=>console.log(e))
        
    }, 840000);

  }
   
   , [])

  return (
    
    <BrowserRouter>
    
      <Routes>
        
        <Route path="/"
          element={
            <authContext.Provider value={{ isloggedIn, setIsLoggedIn, worker, setWorker}}>
              {/* {isloggedIn === true ? <DashboardPage /> :   <LoginPage /> } */}
              <LoginPage />
           
            </authContext.Provider>
          }
        />
        <Route
          path="newlogin"
          element={
            <authContext.Provider value={{ isloggedIn, setIsLoggedIn }}>
              <RequireAuth redirectTo="/">
              <authContext.Provider value={{ isloggedIn, setIsLoggedIn, worker, setWorker }}>
                <ChangePassword />
                </authContext.Provider>
              </RequireAuth>
            </authContext.Provider>
          }
        />
           <Route
          path="dashboard"
          element={
            <authContext.Provider value={{ isloggedIn, setIsLoggedIn }}>
              <RequireAuth redirectTo="/">
              <authContext.Provider value={{ isloggedIn, setIsLoggedIn, worker, setWorker }}>
                <BillContext.Provider value={{
                  meals, 
                  setMeals, 
                  total, 
                  setTotal, 
                  bills, 
                  setBills,
                  currentCategory,
                  setCurrentCategory,
                  addItem,
                  minusItem
                  }}>
                <DashboardPage />
                    {/* <PosTemp /> */}
                </BillContext.Provider>
                </authContext.Provider>
              </RequireAuth>
            </authContext.Provider>
          }
        />
               <Route
          path="bills"
          element={
            <authContext.Provider value={{ isloggedIn, setIsLoggedIn }}>
              <RequireAuth redirectTo="/">
              <authContext.Provider value={{ isloggedIn, setIsLoggedIn, worker, setWorker }}>
                <BillPage />
                </authContext.Provider>
              </RequireAuth>
            </authContext.Provider>
          }
        />
        <Route
          path="onlineorders"
          element={
            <authContext.Provider value={{ isloggedIn, setIsLoggedIn }}>
              <RequireAuth redirectTo="/">
              <authContext.Provider value={{ isloggedIn, setIsLoggedIn, worker, setWorker }}>
                <OnlineOrdersPage />
                </authContext.Provider>
              </RequireAuth>
            </authContext.Provider>
          }
        />
       <Route
              path="expense"
              element={
                <authContext.Provider value={{ isloggedIn, setIsLoggedIn }}>
                  <RequireAuth redirectTo="/">
                  <authContext.Provider value={{ isloggedIn, setIsLoggedIn, worker, setWorker }}>
                    <ExpensePage />
                    </authContext.Provider>
                  </RequireAuth>
                </authContext.Provider>
              }
            />
      </Routes>
    </BrowserRouter>
  );
}
function RequireAuth({ children, redirectTo }) {
  const { isloggedIn } = useContext(authContext);
  return isloggedIn ? children : <Navigate to={redirectTo} />;
}

export default App;
