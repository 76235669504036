import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import {useState, useEffect} from 'react'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  height:"70%",
  p: 4,
};

export default function PaymentCalculator(props) {
    let numbers = [0,1,2,3,4,5,6,7,8,9]
    const [amountPaid, setAmountPaid] = useState("")
    const [change, setChange] = useState(0)
    useEffect(()=>{
        setChange("")
        setAmountPaid("")
        
    }, [])
    
    
    const displayAmountPaid = (amount) =>{        
        if(amount === "CE"){
            setAmountPaid("")
            setChange("")
        } else {
            setAmountPaid(amountPaid.toString() + amount)
        }    
    }

    const caclulateChange = () =>{
      setChange(eval(props.total - amountPaid < 0 ? eval(props.total - amountPaid) *-1 : eval(props.total - amountPaid)))
      
    }

    const completeCheckeout = () =>{
      props.completeCheckout(change)
        setChange("")
        props.resetTotal()
        setAmountPaid("")
        props.onShowPaymentModule();
       
    }

  return (
    <div>
      <Modal
        open={props.showPaymentModule}
        onClose={props.onShowPaymentModule}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form>
          <TextField id="filled-basic" label={"$"+props.total} variant="filled" style={{margin:"10px", width:"90%"}}/>
          <br />
          <TextField id="outlined-basic" 
                value={amountPaid}
                variant="outlined"  style={{margin:"10px", width:"90%"}}/>
                <br />
                <span style={{color:"red"}}>Change</span>
          <TextField id="filled-basic" value={change}  variant="filled" style={{margin:"10px", width:"90%"}}/>
          </form>
          {numbers.map((el, i)=>(          
          <button onClick={(e) =>displayAmountPaid(i)} value={i} key={i} style={{width:"20%", height:"10%", margin:"4px"}}>{i}</button>
          ))}
          <button onClick={(e) =>displayAmountPaid(".")} style={{width:"20%", height:"10%", margin:"4px"}}>.</button>
          <button onClick={(e) =>displayAmountPaid("CE")} style={{width:"20%", height:"10%", margin:"4px"}}>CE</button>
          <div style={{display:'flex', flexDirection:"row", justifyContent:"center"}}>

          <Button variant="contained" style={{ margin:"5px" ,borderRadius:"10px", width:"30%"}}onClick={()=>caclulateChange()}>Calculate Change</Button> <br />
          <Button variant="contained" style={{margin:"5px",  borderRadius:"10px", width:"30%"}}onClick={()=>completeCheckeout()}>Complete Checkout</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}