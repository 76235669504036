import React from 'react'
import {useState, useEffect, useContext} from "react"
import authContext from "../context/authContext";
import moment from 'moment'
import Paper from "@mui/material/Paper";
const Test = () => {
  const [bills, setBills] = useState([]);
  const [value, onChange] = useState(new Date());
  const { worker } = useContext(authContext);
  useEffect(()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + worker.accessToken);
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
    };
    
    fetch(`https://${process.env.REACT_APP_URL}/bills`, requestOptions)
    
      .then((response) => response.json())
      .then((data) => { 
        console.log(data)
        setBills(data.data)})
    

  },[])
  return (

      <div>
        

        {bills && bills.length > 0 && bills.map((el)=>(<div>
          Order #:{el.orderNumber} <br/>
          Date: {el.date}
          {el.data.map((el)=>(<div>{el.name} {" "} {el.description}{" "} {el.quantity}{el.change? "Change: $" : ""} {el.change}  {el.total ? "Total: $" + el.total : ""}</div>))}
          <br />
          </div>))}
      </div>
  
  )
}

export default Test
