import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import uuid from "uuid";
import "./Scrollable.css";
import billContext from "../context/BillContext";

const Messages = ({ messages }) => {
  const { bills, addItem, minusItem, total } = useContext(billContext)
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [bills]);

  return (
    <div className="messagesWrapper">
      {bills.map((el, i) => (
        <div key={i} style={{display: "flex"
        , direction:"row"
        , justifyContent:"center"
        ,AlignItems:"stretch"
        }}> 
              {i + 1}.
             
               {el.name}
              
          
               <button style={{ 
                        
                         marginLeft:"10px", 
                         marginRight: "10px", 
                         borderRadius:"20px",
                         backgroundColor:"white",
                         border:"1px solid #2196f3 ",   
                    border:"1px solid #2196f3 " 
              
              }}
              id={el.id}
              onClick={(e) => addItem(e.target.id)}
              >+
              </button>
                {el.quantity}  
               <button 
                style={{
                              marginLeft:"10px", 
                            marginRight: "10px", 
                            borderRadius:"20px",
                       
                            backgroundColor:"white",
                            border:"1px solid #2196f3 ",               
                        }}
              id={el.id}
              onClick={(e) => minusItem(e.target.id)}
              >-</button>
               {el.price}
           
           
            {/* <div
            style={{ marginLeft:"10px", width: "25%", marginBottom: "10px" }}
            >
            {el.name}
            </div>
            <div style={{ maxWidth: "50%", width: "50%" }}>
            <button
              style={{ width: "25%", marginRight: "10px", borderRadius:"20px"
              , backgroundColor:"white",
              border:"1px solid #2196f3 " }}
              id={el.id}
              onClick={(e) => addItem(e.target.id)}
            >
              +
            </button>
            {el.quantity}
            <button
              id={el.id}
              onClick={(e) => minusItem(e.target.id)}
              style={{ width: "25%", marginLeft: "10px",
              width: "25%", borderRadius:"20px"
              , backgroundColor:"white",
              border:"1px solid #2196f3 " }}
            >
              -
            </button>
            </div>
            <div style={{ maxWidth: "30%", width: "30%" }}>
            ${el.price}
            </div> */}
            </div>
      
      
      
      ))}
       Total: ${total}
      <div ref={messagesEndRef} />
    </div>
  );
};

function App() {
  const [messages, setMessages] = useState([]);
  const addMessages = () => {
    setMessages(m => [...m, uuid()]);
  };
  return (
    <div className="App">
      <Messages messages={messages} />
    </div>
  );
}

export default App;
