import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import imageCompression from 'browser-image-compression';
import Header from "../components/Header";
import Button from '@mui/material/Button';
import {useState, useContext, useEffect} from 'react'
import DatePicker from 'react-date-picker';
import authContext from "../context/authContext";
import CircularProgress from '@mui/material/CircularProgress';

const ExpensePage = () => {
    const [merchant, setMerchant] = useState("");
    const [merchantError, setMerchantError] = useState("")
    const [amountError, setAmountError] = useState("")
    const [amount, setAmount] = useState("")
    const [notes, setNotes] = useState("")
    const [image, setImage] = useState("")
    const [value, onChange] = useState(new Date());
    const [date, setDate] = useState("");
    const { worker } = useContext(authContext);
    const [loading, setLoading] = useState(false)
    const [expenses, setExpenses] = useState([])

    useEffect(()=> {
      let date = new Date()
      let dayTime = date.getDate().toString() + "/" + date.getMonth().toString()+ "/" +date.getFullYear().toString()
      setDate(dayTime)
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + worker.accessToken);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        
      };
      
      fetch(`https://${process.env.REACT_APP_URL}/expenses`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          setExpenses(data.data)
          //   meals.sort(function(a, b) {
          //     var textA = a.name.toUpperCase();
          //     var textB = b.name.toUpperCase();
          //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          // })
        })
        .catch(error => console.log('error', error));
    

    }, [])
    const dateChanger = (date)=>{

      let dayTime = date.getDate().toString() + "/" + date.getMonth().toString()+ "/" +date.getFullYear().toString()
      setDate(dayTime)
      onChange(date)
    }

    const addItem = () =>{
      setMerchantError(false);
      setAmountError(false);
   
      let newMeal = {
          "merchant": merchant,
          "amount": amount,
          "notes": notes,
          "date": date,
          "image": image
          
      }
      let check = 1
      if(check){
        if(!merchant){
          setMerchantError(true)
          
          check = 0
        }
       
    
        
     
        if(!amount){
          setAmountError(true)
          check = 0;
        }
    
   
      }
      console.log(check)
  
      if(check) {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${worker.accessToken}`);
        myHeaders.append("Content-Type", "application/json");
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify(newMeal),
          redirect: 'follow'
        };
  
        fetch(`https://${process.env.REACT_APP_URL}/addnewexpense` ,requestOptions)
      // .then(response => response.json())
  
      //   fetch("http://192.168.2.84:4000/newmeal" ,{
        
      //     method:"POST",
      //     headers: {
      //         "Content-Type": "application/json",
      //         "Access-Control-Origin": "*",
      //       },
      //       body: JSON.stringify(newMeal)
      // })
      .then(response => response.json())
      .then(data => {
          if(data.data) {
            setAmount("")
            setMerchant("")
            let date = new Date()
            onChange(date)
            let dayTime = date.getDate().toString() + "/" + date.getMonth().toString()+ "/" +date.getFullYear().toString()
            setDate(dayTime)
            setImage("")
            setLoading(false)
            setNotes("")

            var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + worker.accessToken);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        
      };
      
      fetch(`https://${process.env.REACT_APP_URL}/expenses`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setExpenses(data.data.reverse())
          //   meals.sort(function(a, b) {
          //     var textA = a.name.toUpperCase();
          //     var textB = b.name.toUpperCase();
          //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          // })
        })
        .catch(error => console.log('error', error));
          }
          
          // props.onChangeMeal([...data.data])    
        
      })
      }
    }

     const   handleImageUpload = async (e)=>{
     async function handleImageUpload(event) {

        const imageFile = event.target.files[0];
        console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
      
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return imageCompression.getDataUrlFromFile(compressedFile)
        //   await uploadToServer(compressedFile); // write your own logic
        } catch (error) {
          console.log(error);
        }
      
      }
      let image = await handleImageUpload(e)
      setImage(image)

   }
  return (
    <div style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
        <Header />
        <div style={{display:"flex", justifyContent:"center", padding:"20px"}}>
             <h1>Record Expenses</h1>
        </div>
        <br />
        <div style={{display:"flex", justifyContent:"center"}}>
         <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic" label="Merchant" variant="outlined" value={merchant} onChange={(e)=>{setMerchant(e.target.value)}}/> <br />
      <TextField id="outlined-basic" label="amount" variant="outlined" value={amount} onChange={(e)=>{setAmount(e.target.value)}}/><br />
      <DatePicker format="dd-MM-y" disableClock ={true}onChange={(e)=>dateChanger(e)} value={value} /> <br />
      <TextField id="outlined-basic" label="note" variant="outlined" value={notes} onChange={(e)=>{setNotes(e.target.value)}}/><br />
      <input type="file" accept="image/*" onChange={(event)=>handleImageUpload(event)}/>
    <br /> <img src={image} />
     <br /> {loading ?   <CircularProgress /> : <Button onClick={(e)=>{addItem()}}>Submit Bill</Button>}
    </Box>
    </div>
    <div style={{display:"flex", justifyContent:"center", border:"1px solid black"}}>
          {expenses.length > 0 && <h2>Expenses</h2>} 
    </div>
    <div style={{display:"flex", justifyContent:"center", border:"1px solid black", flexDirection:"column"}}>
    {expenses.length > 0 &&  expenses.map((el, i)=>(
      <div style={{padding:"20px", border:"1px solid gray"}}>
                          Bill#:{i}<br />
                          Date: {el.date} <br />
                          Merchant: {el.merchant} <br />
                          Amount: {el.amount} <br />
                          Notes: {el.notes} <br />
                          Image: <br />
                          <img src={el.image} style={{width:"100%"}}/>
                          <br />
                      </div>
      
      ))
    }
    </div>
    
   
    </div>
  )
}

export default ExpensePage
