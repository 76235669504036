import React from 'react'
import Header from "../Header"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useState, useEffect, useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import authContext from "../../context/authContext";
import billContext from "../../context/BillContext";
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import AddItemOnlineModal from "./AddItemOnlineModal";
import OnlineOrderingCard from './OnlineOrderingCard';



const OnlineORderPortal = () => {
  
    const [description, setDescription] = useState("");
    const [isAvailable, setisAvailable] = useState(false)
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [_id, set_id] = useState("");
    const [_rev, set_rev] = useState("");
    const { worker ,} = useContext(authContext);
    const [loading, setLoading] = useState(false)
    const [onlineMeals, setOnlineMeals] = useState([])
    useEffect(()=>{
        getItem();

    }, [])
    const getItem = () =>{
        fetch(`https://${process.env.REACT_APP_URL}/chorisPav`)
        .then(response=>response.json())
        .then((data)=>{
          console.log(data.data)
          setOnlineMeals(data.data)
            // setDescription(data.data.description)
            // setisAvailable(data.data.isAvailable)
            // setName(data.data.name)
            // setPrice(data.data.price)
            // setQuantity(data.data.quantity)
            // set_id(data.data._id)
            // set_rev(data.data._rev)
        }
               
        )
        .catch((e)=>console.log(e))
    }

  const changeItems = (index, name, price, quantity) =>{
   
    let temp = onlineMeals
    if(name){
      temp[index].name =  temp[index].name + name
     console.log(name)
    }

    if(price){
      temp[index].price = price
    }

    if(quantity){
      temp[index].quantity = quantity
    }

    setOnlineMeals(temp)
   
  }

const changeAvailablity = (availablity, id) =>{
    let newMeal = {}
    if(availablity){
         newMeal = {
            "_id": id,
            "_rev":_rev,
            "isAvailable": "Bazinga"
    
            
        }  
    
            }else {
            newMeal = {
                "name": name,
                "description": description,
                "price": parseFloat(price),
                "quantity": parseFloat(quantity),
                "_id": id,
                "_rev":_rev,
        }
    }
   
    var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${worker.accessToken}`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(newMeal),
        redirect: 'follow'
      };

    fetch(`${process.env.REACT_APP_URL}/changeAvaialblity` ,requestOptions)
    // .then(response => response.json())

    //   fetch("http://192.168.2.84:4000/newmeal" ,{
      
    //     method:"POST",
    //     headers: {
    //         "Content-Type": "application/json",
    //         "Access-Control-Origin": "*",
    //       },
    //       body: JSON.stringify(newMeal)
    // })
    .then(response => response.json())
    .then(data => {
        getItem()
      
      
     
          })
          .catch(error => console.log('error', error)); 

}




  return (
    <div style={{padding:"20px"}}>
       <h1>Online Order Portal</h1> 
       <h2> Activate Items</h2>
       <AddItemOnlineModal getItem={getItem}/> <br />
       {onlineMeals.length > 0 && onlineMeals.map((el, i)=>(
         <OnlineOrderingCard 
          key={i} 
          index={i} 
          meals={el} 
          changeAvailablity={changeAvailablity}
          getItem={getItem}
         />
        
    ))}
    </div>
  )
}

export default OnlineORderPortal
